<template>
    <b-container>
      <div class="text-center mt-4"><h3>Cycles</h3></div>
    </b-container>
</template>

<script>
import { AlertForm, ICON } from '../helpers/Alert';

export default {
  name: 'Cycles',
  components: {
  },

  data() {
    return {
      configRol: {}
    };
  },
  created() {
    this.configRol = this.$store.state.configRol;
    if(this.configRol.cycles?.ventana === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("menu.Cycles")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
}

</script>

<style scoped>


</style>
